import './assets/css/chunk6.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/App.css';
import './assets/css/chatbox.css';
import './assets/css/faq.css';
import './assets/css/timeline.css';

import './assets/css/contact.css';
import './assets/css/shoppingcart.css';
import './assets/css/404.css';
import './assets/css/index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Mainpage from './pages/mainpage';
import ContactUs from './pages/contactus';
import AboutUs from './pages/aboutus';
import Brands from './pages/brands';
import Categories from './pages/categories';
import Cart from './pages/cart/cart';
import Order from './pages/order/order';
import OrderSeller from './pages/order/orderseller';
import Payment from './pages/payment/payment';
import Delivery from './pages/payment/delivery';
import Billing from './pages/payment/billing';
import PaymentResult from './pages/payment/paymentresult';
import Contracts from './pages/contracts';
import ProductDetails from './pages/productdetails';
import PrevAuctionDetails from './pages/prevauctiondetails';
import Search from './pages/search';
import UserContent from './pages/user/usercontent';
import Registration from './pages/registration';
import NoMatch from './pages/nomatch';
import RemindPassword from './pages/remindpassword';
import ResetPassword from './pages/resetpassword';
import LoginPage from './pages/loginpage';
import SalesPage from './pages/salespage';
import SupportCenter from './pages/supportcenter';
import Blog from './pages/blog';
import VerifyEmail from './pages/verifyemail';
import ThreedsRes from './pages/threedsres';
import CargoBarcode from './pages/cargobarcode';
import Maintenance from './pages/maintenance';


import Echo from 'laravel-echo';
import {showToast} from './components/utils';


window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: 'EzatSHYfCfIMsYvlhKUjoiEFMcnk5wzjF6PzWWOXL',
//   cluster: 'mt1',
//   wsHost: 'mezatvar.com.tr',
//   wsPort: 6001,
//   wssPort: 6001,
//   forceTLS: false,
//   encrypted: true,
//   disableStats: false,
//   authEndpoint: 'https://mezatvar.com.tr/api/broadcasting/auth',
//   auth: {
//     headers: {
//         'Authorization': 'Bearer ' + getCookie('access-token')
//     }
//   }
// });

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'EzatSHYfCfIMsYvlhKUjoiEFMcnk5wzjF6PzWWOXL',
    cluster: 'mt1',
    wsHost: 'mezatvar.com.tr',
    wsPort: 6001,
    wssPort: 6001,
    forceTLS: false,
    encrypted: true,
    disableStats: false,
    authEndpoint: 'https://mezatvar.com.tr/api/broadcasting/auth',
    auth: {
        headers: {
            'Authorization': 'Bearer ' + getCookie('access-token')
        }
    }
});

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    let doma = ".mezatvar.com.tr";
    if (navigator.cookieEnabled) {
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + doma + ";Secure;";
    }
}

function getCookie(name) {
    var cookieValue = '';
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function getQueryString(m_val) {
    let query = UseQuery();
    return (query.get(m_val));
}

function UseQuery() {
    return new URLSearchParams(window.location.search);
}

function App() {
    const productroot = 'mezat';
    // const APP_BACKEND_URL = "https://mezatvar.com.tr";
    const APP_BACKEND_URL = window.location.origin;
    window.addEventListener('offline', function (e) {
        showToast("İnternet bağlantınız koptu. Sayfada işlem yapamazsınız", 'bg-danger', 15000);
    });
    window.addEventListener('online', function (e) {
        showToast("İnternet bağlantınız düzeldi. Devam edebilirsiniz", 'bg-success', 8000);
    });
    let visitor_uid = getCookie('visitor_uid');
    if (!visitor_uid) {
        visitor_uid = uuid();
        setCookie('visitor_uid', visitor_uid, 30);
    }

    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Mainpage apiurl={APP_BACKEND_URL} slug="anasayfa"/>
                </Route>
                <Route exact path={["/destekmerkezi", "/destekmerkezi/:category?/:anchorlink?"]} render={({match}) => (
                    <SupportCenter apiurl={APP_BACKEND_URL} activetab={match.params.category}
                                   anchorlink={match.params.anchorlink} slug="destekmerkezi"/>
                )}/>
                <Route exact path={["/blog", "/blog/:category?/:anchorlink?"]} render={({match}) => (
                    <Blog apiurl={APP_BACKEND_URL} activetab={match.params.category}
                          anchorlink={match.params.anchorlink} slug="blog"/>
                )}/>

                <Route path="/satisyap">
                    <SalesPage apiurl={APP_BACKEND_URL} slug="satisyap"/>
                </Route>
                <Route path="/sepetim">
                    <Cart apiurl={APP_BACKEND_URL} slug="sepetim"/>
                </Route>

                <Route path="/teslimat">
                    <Delivery apiurl={APP_BACKEND_URL} slug="teslimat"/>
                </Route>
                <Route path="/fatura">
                    <Billing apiurl={APP_BACKEND_URL} slug="fatura"/>
                </Route>
                <Route path="/odeme">
                    <Payment apiurl={APP_BACKEND_URL} slug="odeme"/>
                </Route>
                <Route exact path={["/paymentresult", "/paymentresult/:paymentid"]} render={({match}) => (
                    <PaymentResult paymentid={match.params.paymentid} apiurl={APP_BACKEND_URL} slug="odemesonucu"/>
                )}/>

                <Route path="/threedsres">
                    <ThreedsRes apiurl={APP_BACKEND_URL} slug="iyzico_sonuc"/>
                </Route>
                <Route path="/barcode">
                    <CargoBarcode/>
                </Route>
                <Route path="/hakkimizda">
                    <AboutUs apiurl={APP_BACKEND_URL} slug="hakkimizda"/>
                </Route>
                <Route path="/iletisim">
                    <ContactUs apiurl={APP_BACKEND_URL} slug="iletisim"/>
                </Route>
                <Route exact path={"/u/:name/"} render={({match}) => (
                    <UserContent activetab={match.params.name} apiurl={APP_BACKEND_URL} slug="kullanici"/>
                )}/>

                <Route exact path={["/sozlesmeler", "/sozlesmeler/:name"]} render={({match}) => (
                    <Contracts activetab={match.params.name} apiurl={APP_BACKEND_URL} slug="sozlesmeler"/>
                )}/>

                <Route exact path={["/kategori", "/kategori/:anakategori?/:kategori?/:altkategori?"]}
                       render={({match}) => (
                           <Search title="Kategorideki mezatlar" q={getQueryString("q")}
                                   anakategori={match.params.anakategori} kategori={match.params.kategori}
                                   altkategori={match.params.altkategori} apiurl={APP_BACKEND_URL}
                                   criteriaLink={"/api/search"} directory={"kategori"} slug={"kategori"}/>
                       )}/>
                <Route path="/markalar">
                    <Brands apiurl={APP_BACKEND_URL} slug="markalar"/>
                </Route>
                <Route path="/kategoriler">
                    <Categories apiurl={APP_BACKEND_URL} slug="kategoriler"/>
                </Route>
                <Route exact path={["/firsatlar", "/firsatlar/:anakategori?/:kategori?/:altkategori?"]}
                       render={({match}) => (
                           <Search title="Kaçırılmayacak Fırsatlar" show_hot={true}
                                   anakategori={match.params.anakategori} kategori={match.params.kategori}
                                   altkategori={match.params.altkategori} apiurl={APP_BACKEND_URL}
                                   criteriaLink={"/api/showhot"} directory={"firsatlar"} slug="firsatlar"/>
                       )}/>
                <Route exact path={["/gunun_mezatlari", "/gunun_mezatlari/:anakategori?/:kategori?/:altkategori?"]}
                       render={({match}) => (
                           <Search title="Günün Mezatları" show_daily={true} anakategori={match.params.anakategori}
                                   kategori={match.params.kategori} altkategori={match.params.altkategori}
                                   apiurl={APP_BACKEND_URL} criteriaLink={"/api/showdaily"}
                                   directory={"gunun_mezatlari"} slug="gunun_mezatlari"/>
                       )}/>

                <Route exact path={["/gelecek_mezatlar", "/gelecek_mezatlar/:anakategori?/:kategori?/:altkategori?"]}
                       render={({match}) => (
                           <Search title="Gelecek Mezatlar" show_next={true} anakategori={match.params.anakategori}
                                   kategori={match.params.kategori} altkategori={match.params.altkategori}
                                   apiurl={APP_BACKEND_URL} criteriaLink={"/api/shownext"}
                                   directory={"gelecek_mezatlar"} slug="gelecek_mezatlar"/>
                       )}/>
                <Route path="/kayitol">
                    <Registration apiurl={APP_BACKEND_URL} slug="kayitol"/>
                </Route>
                <Route path="/girisyap">
                    <LoginPage apiurl={APP_BACKEND_URL} slug="girisyap"/>
                </Route>
                <Route path={"/ara"}>
                    <Search title={getQueryString("q") + " için arama sonuçları"} q={getQueryString("q")}
                            apiurl={APP_BACKEND_URL} criteriaLink={"/api/search"} directory={"arama"} slug={"arama"}/>
                </Route>
                <Route path="/sifremiunuttum">
                    <RemindPassword apiurl={APP_BACKEND_URL} slug="sifremi_unuttum"/>
                </Route>
                <Route exact path={"/sifreyenile/:token/:email"} render={({match}) => (
                    <ResetPassword apiurl={APP_BACKEND_URL} token={match.params.token} email={match.params.email}
                                   slug="sifreyenile"/>
                )}/>
                <Route exact path={"/epostadogrula/:id/:hash"} render={({match}) => (
                    <VerifyEmail apiurl={APP_BACKEND_URL} id={match.params.id} hash={match.params.hash}
                                 expires={match.params.expires} slug="eposta_dogrula"/>
                )}/>
                <Route exact path={"/" + productroot + "/:slug"} render={({match}) => (
                    <ProductDetails productslug={match.params.slug} apiurl={APP_BACKEND_URL}
                                    slug={productroot + (match.params.slug ? "/" + match.params.slug : null)}/>
                )}/>
                <Route exact path={"/mezatozeti/:slug"} render={({match}) => (
                    <PrevAuctionDetails productslug={match.params.slug} apiurl={APP_BACKEND_URL}
                                        slug={productroot + (match.params.slug ? "/" + match.params.slug : null)}/>
                )}/>
                <Route exact path={"/siparisdetay/:orderid"} render={({match}) => (
                    <Order orderid={match.params.orderid} apiurl={APP_BACKEND_URL} slug='siparis'/>
                )}/>

                <Route exact path={"/satisdetay/:orderid"} render={({match}) => (
                    <OrderSeller orderid={match.params.orderid} apiurl={APP_BACKEND_URL} slug='satisdetay'/>
                )}/>
                <Route path="/maintenance">
                    <Maintenance apiurl={APP_BACKEND_URL}/>
                </Route>
                <Route path="*">
                    <NoMatch apiurl={APP_BACKEND_URL} slug="404"/>
                </Route>
            </Switch>
        </Router>
    );
}


export default App;
