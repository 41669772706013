import React, {Component} from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import UserLeftMenu from './userleftmenu';
import NotAuthorized from '../notauthorized';
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";
import DateCombo from '../../components/datecombo/datecombo';
import ModalForm from '../../components/modal/modalform';

import UserMobileMenu from './usermobilemenu';
import swal from 'sweetalert2';
import Stepper from "../../components/stepper/stepper";
import {Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper} from "@material-ui/core";


export class MyAddress extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    state = {
        errorDesc: '',
        isLoading: false,
        isAuthenticated: false,
        isOpen: false,
        activeModal: null,
        err: false,
        title: '',
        firstName: '',
        lastName: '',
        TCKN: '',
        phone: '',
        gun: 1,
        ay: 1,
        yil: 1980,
        verification_code: '',
        email_verified_at: null,
        bankIban: '',
        bankAccountName: '',
        useraddresses: [],

        userid: 0,

        deliveryPerson: "",
        deliveryPhone: "",
        deliveryAddress: "",
        deliveryCity: "",
        deliveryProvince: "",
        deliveryPostalCode: "",
        deliveryCountry: "",
    }


    // handleSubmit = event => {
    //     event.preventDefault();
    //     var retype = 'success';
    //     const {cookies} = this.props;
    //     if (this.state.TCKN.length === 11) {
    //         var formData = new FormData()
    //         formData.append('title', this.state.title);
    //         formData.append('Ad', this.state.firstName);
    //         formData.append('Soyad', this.state.lastName);
    //         formData.append('TCKN', this.state.TCKN);
    //         formData.append('DogumGunu', this.state.gun);
    //         formData.append('DogumAyi', this.state.ay);
    //         formData.append('DogumYili', this.state.yil);
    //         formData.append('Telefon', this.state.phone);
    //         formData.append('HesapAdi', this.state.bankAccountName);
    //         formData.append('IBAN', this.state.bankIban);
    //
    //         fetch(this.props.apiurl + '/api/setProfileDetails', {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Authorization': 'Bearer ' + cookies.get('access-token'),
    //             },
    //             credentials: 'include',
    //             body: formData
    //         })
    //             .then((response) => {
    //                 if (!response.ok) {
    //                     retype = 'error';
    //                     return response.json();
    //                 } else {
    //                     retype = 'success';
    //                     return response.json();
    //                 }
    //             })
    //             .then((responseData) => {
    //                 if (responseData.message === 'Unauthenticated.') {
    //                     swal.fire({
    //                         icon: 'error',
    //                         text: "Profil detayı güncellemek için üye girişi yapmanız gerekiyor",
    //                         timer: 5000,
    //                         showConfirmButton: false,
    //                         showCancelButton: false,
    //                         showCloseButton: true,
    //                         closeButtonCaption: 'Kapat'
    //                     });
    //                 } else if (responseData.message === "Kimlik bilgileri geçersiz") {
    //                     swal.fire({
    //                         icon: 'error',
    //                         text: "Kimlik bilgileri doğrulanamadı",
    //                         timer: 5000,
    //                         showConfirmButton: false,
    //                         showCancelButton: false,
    //                         showCloseButton: true,
    //                         closeButtonCaption: 'Kapat'
    //                     });
    //                 } else {
    //                     swal.fire({
    //                         icon: retype,
    //                         text: responseData.message,
    //                         timer: 5000,
    //                         showConfirmButton: false,
    //                         showCancelButton: false,
    //                         showCloseButton: true,
    //                         closeButtonCaption: 'Kapat'
    //                     });
    //                     this.setState({isOpen: true, activeModal: 0})
    //                 }
    //             })
    //
    //             .catch((error) => {
    //                 swal.fire({
    //                     icon: 'error',
    //                     text: 'Bir hata oluştu. ' + error,
    //                     timer: 7000,
    //                     showConfirmButton: false,
    //                     showCancelButton: false,
    //                     showCloseButton: true,
    //                     closeButtonCaption: 'Kapat'
    //                 });
    //             });
    //     } else {
    //         swal.fire({
    //             icon: 'warning',
    //             text: 'Geçersiz TC Kimlik No. Lütfen 11 haneli TC Kimlik Numaranızı girin',
    //             timer: 5000,
    //             showConfirmButton: false,
    //             showCancelButton: false,
    //             showCloseButton: true,
    //             closeButtonCaption: 'Kapat'
    //         });
    //     }
    // }

    // componentDidMount() {
    //     this.setState({isLoading: true, isAuthenticated: false});
    //     const {cookies} = this.props;
    //
    //     fetch(this.props.apiurl + '/api/user', {
    //         method: 'GET',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Authorization': 'Bearer ' + cookies.get('access-token'),
    //         },
    //         credentials: 'include'
    //     })
    //         .then((response) => {
    //             if (!response.ok) {
    //                 return response.json();
    //             } else {
    //                 return response.json();
    //             }
    //         })
    //         .then((responseData) => {
    //             if (responseData.message === 'Unauthenticated.') {
    //                 this.setState({isLoading: false, isAuthenticated: false})
    //             } else {
    //                 if (responseData) {
    //                     this.setState({
    //                         title: responseData.user.title,
    //                         firstName: responseData.user.first_name,
    //                         lastName: responseData.user.last_name,
    //                         phone: responseData.user.phone_1,
    //                         email: responseData.user.email,
    //                         TCKN: responseData.user.tckn,
    //                         email_verified_at: responseData.user.email_verified_at,
    //                         gun: parseInt(responseData.user.birth_day),
    //                         ay: parseInt(responseData.user.birth_month),
    //                         yil: parseInt(responseData.user.birth_year),
    //                         isLoading: false,
    //                         isAuthenticated: true,
    //                         bankAccountName: responseData.user.bank_account_name,
    //                         bankIban: responseData.user.bank_iban,
    //                     })
    //                 }
    //             }
    //         })
    //
    //         .catch((error) => {
    //             this.setState({errorDesc: error});
    //         });
    // }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }

    openModal = () => this.setState({isOpen: true});
    closeModal = () => this.setState({isOpen: false, activeModal: null});
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value, changed: true});
    }

    handleDeliveryProvinceChange = (event) => {
        this.setState({[event.target.name]: event.target.value, changed: true});
        this.getCities(event.target.value);
    }

    getProvinces() {
        fetch(this.props.apiurl + '/api/iller', {method: 'GET', credentials: 'include'})
            .then(res => res.json())
            .then((data) => {
                this.setState({iller: data});
            })
            .catch(err => {
                this.setState({errorDesc: err});
            })
    }

    getCities(province) {
        fetch(this.props.apiurl + '/api/ilceler/' + province, {method: 'GET', credentials: 'include'})
            .then(res => res.json())
            .then((data) => {
                this.setState({deliverycities: data});
            })
            .catch(err => {
                this.setState({errorDesc: err});
            })
    }

    getUsersAddresses = async (userid) => {
        // console.log("getUsersAddresses " + userid);
        console.log("getUsersAddresses");

        var fetchURL = "/api/MezatVar/FetchAddressesByUserId";
        var postData = this.state;

        postData["userId"] = userid;

        await fetch(fetchURL, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(postData)
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);

                if (result.resultstate === 0) {
                    console.log("getUsersAddresses Succeeded");
                    this.setState({
                        useraddresses: result.useraddresses
                    });
                } else {
                    console.log("getUsersAddresses Error");
                }
            });
    }

    handleSubmit = event => {
        event.preventDefault();
        var retype = 'btn-green';
        this.setState({isOpen: false});
        if (this.state.changed) {
            const {cookies} = this.props;
            // var formData = new FormData()

            // formData.append('delivery_contact_name', this.state.deliveryPerson)
            // formData.append('delivery_contact_phone', this.state.deliveryPhone)
            // formData.append('delivery_address', this.state.deliveryAddress)
            // formData.append('delivery_town', this.state.deliveryCity)
            // formData.append('delivery_city', this.state.deliveryProvince)
            // formData.append('delivery_postalcode', this.state.deliveryPostalCode)
            // formData.append('delivery_country', this.state.deliveryCountry)
            // formData.append('user_id', this.state.userid)
            // formData.append('additional_notes', this.state.additionalNotes)

            var fetchURL = "/api/MezatVar/InsertAddressByUserId";
            var postData = this.state;

            postData["delivery_contact_name"] = this.state.deliveryPerson;
            postData["delivery_contact_phone"] = this.state.deliveryPhone;
            postData["delivery_address"] = this.state.deliveryAddress;
            postData["delivery_town"] = this.state.deliveryCity;
            postData["delivery_city"] = this.state.deliveryProvince;
            postData["delivery_postalcode"] = this.state.deliveryPostalCode;
            postData["delivery_country"] = this.state.deliveryCountry;
            postData["user_id"] = this.state.userid;
            postData["additional_notes"] = this.state.additionalNotes;

            fetch(fetchURL, {
                method: "POST", headers: {
                    Authorization: "Bearer " + localStorage.getItem("authtoken"),
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }, body: JSON.stringify(postData)
            })
                .then((response) => {
                    if (!response.ok) {
                        retype = 'error';
                        return response.json();
                    } else {
                        retype = 'success';
                        return response.json();
                    }
                })
                .then((responseData) => {
                    if (responseData.message === 'Unauthenticated.') {
                        swal.fire({
                            icon: "error",
                            text: 'Teslimat bilgileri üzerinde değişiklik yapmak için üye girişi yapmalısınız',
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                        });
                    } else {
                        if (retype === 'error') {
                            swal.fire({
                                icon: retype,
                                text: responseData.message,
                                timer: 5000,
                                showConfirmButton: false,
                                showCancelButton: false,
                                showCloseButton: true,
                                closeButtonCaption: 'Kapat'
                            });
                        } else {
                            window.location = '/fatura';
                        }
                    }
                })
                .catch((error) => {
                    swal.fire({
                        icon: 'error',
                        text: 'Bir hata oluştu. ' + error,
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });
                });
        } else {
            window.location = '/fatura';
        }
    }

    componentDidMount() {

        this.setState({isLoading: true, isAuthenticated: false});
        const {cookies} = this.props;

        console.log(this.props.apiurl + '/api/user');
        console.log("component mount");

        fetch(this.props.apiurl + '/api/user', {
            method: 'GET', headers: {
                'Accept': 'application/json', 'Authorization': 'Bearer ' + cookies.get('access-token'),
            }, credentials: 'include'
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json();
                } else {
                    return response.json();
                }
            })
            .then((responseData) => {

                console.log(responseData);
                console.log(responseData.user.id);
                console.log("user response data");

                if (responseData.message === 'Unauthenticated.') {
                    this.setState({isLoading: false, isAuthenticated: false})
                } else {
                    if (responseData) {
                        this.setState({
                            title: responseData.user.title,
                            userid: responseData.user.id,
                            firstName: responseData.user.first_name,
                            lastName: responseData.user.last_name,
                            phone: responseData.user.phone_1,
                            email: responseData.user.email,
                            TCKN: responseData.user.tckn,
                            email_verified_at: responseData.user.email_verified_at,
                            gun: parseInt(responseData.user.birth_day),
                            ay: parseInt(responseData.user.birth_month),
                            yil: parseInt(responseData.user.birth_year),
                            isLoading: false,
                            isAuthenticated: true,
                            bankAccountName: responseData.user.bank_account_name,
                            bankIban: responseData.user.bank_iban,
                        });

                        this.getUsersAddresses(responseData.user.id);
                    }
                }
            })

            .catch((error) => {
                this.setState({errorDesc: error});
            });


        // this.props.veri &&
        // this.setState({
        //     cart_id: this.props.veri.id,
        //     cart_total_buyer: this.props.veri.cart_total_buyer,
        //     cart_items: this.props.veri.cart_item,
        //     items_total: this.props.veri.items_total,
        //     commissions_total_buyer: this.props.veri.commissions_total_buyer,
        //     deliveryPerson: this.props.veri.delivery_contact_name,
        //     deliveryPhone: this.props.veri.delivery_contact_phone,
        //     deliveryAddress: this.props.veri.delivery_address,
        //     deliveryCity: this.props.veri.delivery_town != null ? this.props.veri.delivery_town.toLocaleUpperCase() : '',
        //     deliveryProvince: this.props.veri.delivery_city != null ? this.props.veri.delivery_city.toLocaleUpperCase() : '',
        //     deliveryPostalCode: this.props.veri.delivery_postalcode,
        //
        //     additionalNotes: this.props.veri.additional_notes
        // })
        //
        // this.getCities(this.props.veri.delivery_city != null ? this.props.veri.delivery_city.toLocaleUpperCase() : '');
        this.getProvinces();

        console.log("delivery details component mount");
        console.log(this.props);
    }

    render() {
        document.title = "Mezatvar - Adreslerim"
        if (this.state.isAuthenticated === true) {
            return (!this.state.isLoading && <div className="App">
                <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
                <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>


                <div className="container bg-white shadow-lg mb-4 mt-4 rounded text-left">

                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="container px-1 px-md-2 px-lg-4 mx-auto text-dark">
                                    <h3 className="mb-1 text-uppercase text-primary">Teslimat Bilgileri </h3>
                                    <p className="smalltext">(*) ile işaretli alanların doldurulması zorunludur</p>
                                    <div className="row d-flex">
                                        <div className="col-sm-6">
                                            <div className="border-0">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <p className="font-weight-bold mb-0">Teslim alacak kişi
                                                            (*)</p> <input className="form-control text-uppercase"
                                                                           type="text" name="deliveryPerson"
                                                                           placeholder="Teslim alacak kişinin adı ve soyadı"
                                                                           size="50"
                                                                           value={this.state.deliveryPerson}
                                                                           onChange={this.handleChange} required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <p className="font-weight-bold mb-0"> Ülke (*)</p>
                                                        <select className="form-select form-control" size="1"
                                                                placeholder="Ülke" name="deliveryCountry"
                                                                id="deliveryCountry" onChange={this.handleChange}>
                                                            <option key="" value="Türkiye">TÜRKİYE</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <p className="font-weight-bold mb-0"> İlçe (*)</p>
                                                        <select className="form-select form-control"
                                                                value={this.state.deliveryCity}
                                                                defaultValue={this.state.deliveryCity} size="1"
                                                                placeholder="İlçe" name="deliveryCity"
                                                                id="deliveryCity" onChange={this.handleChange}
                                                                required>
                                                            <option key="" value="" selected disabled hidden>Lütfen
                                                                bir ilçe seçin
                                                            </option>

                                                            {this.state.deliverycities && this.state.deliverycities.map((e, key) => {
                                                                return <option key={key}
                                                                               value={e.ilce}>{e.ilce}</option>;
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <p className="font-weight-bold mb-0"> Teslimat Adresi
                                                            (*)</p>
                                                        <textarea name="deliveryAddress"
                                                                  placeholder="Teslimat yapılacak açık adresi giriniz"
                                                                  value={this.state.deliveryAddress}
                                                                  className="form-control col-md-12"
                                                                  onChange={this.handleChange} required/>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="border-0">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <p className="font-weight-bold mb-0">Teslim alacak
                                                                kişinin telefonu</p> <input
                                                            className="form-control text-uppercase" type="text"
                                                            name="deliveryPhone" value={this.state.deliveryPhone}
                                                            placeholder="Teslim alacak kişinin telefonu" size="15"
                                                            onChange={this.handleChange}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <p className="font-weight-bold mb-0"> İl (*)</p>
                                                            <select className="form-select form-control" size="1"
                                                                    value={this.state.deliveryProvince}
                                                                    defaultValue={this.state.deliveryProvince}
                                                                    placeholder="İl" name="deliveryProvince"
                                                                    id="deliveryProvince"
                                                                    onChange={this.handleDeliveryProvinceChange}
                                                                    required>
                                                                <option key="" value="" selected disabled
                                                                        hidden>Lütfen bir il seçin
                                                                </option>
                                                                {this.state.iller && this.state.iller.map((e, key) => {
                                                                    return <option key={key}
                                                                                   value={e.il}>{e.il}</option>;
                                                                })}
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <p className="font-weight-bold mb-0">Posta Kodu</p>
                                                            <input className="form-control" type="text"
                                                                   name="deliveryPostalCode"
                                                                   value={this.state.deliveryPostalCode}
                                                                   placeholder="Teslimat adresine ait posta kodu"
                                                                   size="6" minLength="5" maxLength="7"
                                                                   onChange={this.handleChange}/>
                                                        </div>

                                                        <div className="form-group">
                                                            <p className="font-weight-bold mb-0">Adres Tarifi</p>
                                                            <textarea name="additionalNotes"
                                                                      placeholder="Adres tarifinizi giriniz"
                                                                      value={this.state.additionalNotes}
                                                                      className="form-control col-md-12"
                                                                      onChange={this.handleChange}/>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm-12 p-4">
                                <div className="col-sm-6 float-right">
                                    <button type="submit" className="btn btn-blue pull-right"><i
                                        className="fa fa fa-credit-card"></i> Kaydet <i
                                        className="fa fa-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 p-4">
                                <div>Adresler</div>
                                <div><br/></div>

                                <TableContainer component={Paper}>
                                    <Table className="w-full border rounded-lg">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    City
                                                </TableCell>
                                                <TableCell>
                                                    Country
                                                </TableCell>
                                                <TableCell>
                                                    Line1
                                                </TableCell>
                                                <TableCell>
                                                    Line2
                                                </TableCell>
                                                <TableCell>
                                                    Postalcode
                                                </TableCell>
                                                <TableCell>
                                                    Town
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.useraddresses && this.state.useraddresses.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell>{row.city}</TableCell>
                                                    <TableCell>{row.country}</TableCell>
                                                    <TableCell>{row.line1}</TableCell>
                                                    <TableCell>{row.line2}</TableCell>
                                                    <TableCell>{row.postalcode}</TableCell>
                                                    <TableCell>{row.town}</TableCell>
                                                </TableRow>))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                        </div>
                    </form>
                </div>

                <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
                <FooterSection apiurl={this.props.apiurl}/>
            </div>)
        } else {
            return (!this.state.isLoading && <div className="App">
                <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
                <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
                <NotAuthorized/>
                <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
                <FooterSection apiurl={this.props.apiurl}/>
            </div>)
        }

    }

}


export default withCookies(MyAddress);